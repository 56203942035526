<template>
	<v-container fluid class="bg-white h-100">
		<v-layout class="border-bottom py-2 align-center">
			<div class="text-h5">All Company</div>
			<v-spacer></v-spacer>
			<v-select
				hide-details
				style="max-width: 150px"
				:items="planList"
				class="rounded-sm mr-2"
				placeholder="Select Plan"
				outlined
				dense
			></v-select>
			<v-select
				hide-details
				style="max-width: 200px"
				:items="shortByList"
				class="rounded-sm mr-2"
				placeholder="Sort by Last"
				outlined
				dense
			></v-select>
			<v-text-field
				hide-details
				style="max-width: 200px"
				class="rounded-sm mr-2"
				placeholder="Search..."
				outlined
				dense
			></v-text-field>
			<v-btn depressed color="blue darken-4" class="white--text">
				<v-icon>mdi-magnify</v-icon>
			</v-btn>
		</v-layout>
		<v-layout class="my-2" style="gap: 12px">
			<v-flex class="d-flex align-center px-5 red lighten-4 rounded">
				<div class="text-h5 text-capitalize">
					Total Company
					<span class="red--text ms-2 text-h4 fw-600">({{ statics.total_companies}})</span>
				</div>
				<v-spacer></v-spacer>
				<v-icon size="45" color="red lighten-2">mdi-file-chart-outline</v-icon>
			</v-flex>
			<v-flex class="d-flex align-center px-5 blue lighten-4 rounded">
				<div class="text-h5 text-capitalize">
					Total Users
					<span class="red--text ms-2 text-h4 fw-600">({{ statics.total_users }})</span>
				</div>
				<v-spacer></v-spacer>
				<v-icon size="45" color="blue lighten-2">mdi-file-chart-outline</v-icon>
			</v-flex>
			<v-flex class="d-flex align-center px-5 green lighten-4 rounded">
				<div class="text-h5 text-capitalize">
					Last week Login User
					<span class="green--text ms-2 text-h4 fw-600">({{ statics.last_week_login_users }})</span>
				</div>
				<v-spacer></v-spacer>
				<v-icon size="45" color="green lighten-2">mdi-file-chart-outline</v-icon>
			</v-flex>
		</v-layout>
		<v-col md="12" class="pa-0 btt-table" ref="btt_height">
			<table class="table-list" style="table-layout: fixed">
				<thead class="text-uppercase">
					<th width="60">Image</th>
					<th >
						<v-icon size="20" class="cursor-pointer">mdi-sort</v-icon>
						Company name
					</th>
					<th >
						<v-icon size="20" class="cursor-pointer">mdi-sort</v-icon>
						Display name
					</th>
					<th width="120px">Mobile no</th>
					<th width="200px">
						<v-icon size="20" class="cursor-pointer">mdi-sort</v-icon>
						Email address
					</th>
					<th width="90px">Users</th>
					<th width="120px">Package</th>
					<th width="120px">Renewal date</th>
					<th width="200px">
						<v-icon size="20" class="cursor-pointer">mdi-sort</v-icon>
						Last login
					</th>
					<th  width="200px">Created date</th>
				</thead>
				<tbody>
					<tr v-for="(row, index) in rows" :key="index" @click="goToDetail(row.uuid)" class="cursor-pointer">
						<td>
							<v-avatar size="40">
								<template v-if="row.image">
									<v-img :src="row.image" alt="John"></v-img>
								</template>
								<template v-else>
									<v-img
										:src="row.client_logo"
										alt="client-logo"
									></v-img>
								</template>
							</v-avatar>
						</td>
						<td>
							<div>
								<ShowValue :object="row" object-key="client_name" label="company name"/>
								<br>
								<v-chip color="blue darken-4 white--text" style="height: 20px !important;" label> {{ row.client_claim_code }}</v-chip>
							</div>
						</td>
						<td>
							<div>
								<ShowValue :object="row" object-key="display_name" label="display name"/>
							</div>
						</td>
						<td>
							<div>
								<ShowValue :object="row" object-key="mobile_no" label="mobile no"/>
							</div>
						</td>
						<td>
							<div style="max-width: 200px" class="text-truncate">
								<ShowValue :object="row" object-key="client_email" label="email address"/>
							</div>
						</td>
						<td>
							<v-badge color="green" :content="row.active_user" inline left>Active</v-badge>
							<v-badge class="mt-2" color="blue" :content="row.total_user" inline left>Total</v-badge>
						</td>
						<td>
							<v-chip class="my-2" label>
								{{ row.package_name }}
							</v-chip>
						</td>
						<td>
							<div v-if="row.renewal_date">
								{{ formatDate(row.renewal_date) }}
								<div class="red--text" v-if="row.renewal_days < 100">{{ row.renewal_days }} left</div>
							</div>
						</td>
						<td>
							<div>
								<v-icon size="16" class="mr-1">mdi-calendar</v-icon>
								{{ formatDateTime(row.last_login_at) }}
							</div>
							<div class="blue--text">
								<v-icon size="16" class="mr-1">mdi-calendar</v-icon>
								<span v-if="row.last_login_in_days < 1">today</span>
								<span v-else-if="row.last_login_in_days == 1">yesterday</span>
								<span v-else>{{ row.last_login_in_days }} days ago</span>
							</div>
							<div>
								<v-icon size="16" class="mr-1">mdi-account</v-icon>
								{{ row.last_login_user }}
							</div>
						</td>
						<td>{{ formatDateTime(row.added_at) }}</td>
					</tr>
				</tbody>
			</table>
		</v-col>
		<v-layout class="border-top py-2 px-3 align-center pagination-fixed">
			<div class="text-h6">Showing {{ showingFrom }} to {{ showingTo }} of {{ total }} entries</div>
			<v-spacer></v-spacer>
			<v-pagination color="blue darken-4" v-model="pageNo" :length="totalPages" :total-visible="5" v-on:change="getClients()" />
		</v-layout>
	</v-container>
</template>

<script>
import heightMixin from "@/core/mixins/height.mixin";
import { QUERY } from "@/core/services/store/request.module";
import ShowValue from "@/view/components/ShowValue";

export default {
	name: "adminUsers",
	mixins: [heightMixin],
	data() {
		return {
			skipBottomHeight: 68,
			planList: [],
			shortByList: [
				{
					text: "All Users",
					value: "all",
				},
				{
					text: "This Week",
					value: "this_week",
				},
				{
					text: "This Month",
					value: "this_month",
				},
				{
					text: "This Year",
					value: "this_year",
				},
			],
			statics:{
				total_companies:33,
				total_users:20,
				last_week_login_users:10,
			},
			search: null,
			sortField: 'id',
			sortDirection: 'desc',
			offset: 0,
			pageLimit: 10,
			pageNo: 1,
			rows: [],
			total: 0,
			totalPages: 0,
		};
	},
	components: {
		ShowValue
	},
	methods: {
		goToDetail(uuid) {
			this.$router.push({
				name: "clients-detail",
				params: { uuid },
				query: { t: Number(new Date()) }
			});
		},
		getClients() {

			const filter = {
				page_limit: this.pageLimit,
				page_no: this.pageNo,
				search: this.search,
				sort_field: this.sortField,
				sort_direction: this.sortDirection,
			}

			this.$store
				.dispatch(QUERY, { url: "clients", data: filter })
				.then((response) => {
					this.offset = response.offset;
					this.pageLimit = response['page-limit'];
					this.pageNo = response['page-no'];
					this.rows = response.rows;
					this.total = response.total;
					this.totalPages = Math.ceil(this.total / this.pageLimit);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {});
		}
	},
	mounted() {
		this.getClients();
	},
	computed: {		
    showingFrom() {
      return this.rows?.length ? this.offset + 1 : 0;
    },
    showingTo() {
      return this.offset + (this.rows?.length ?? 0);
    },
	}
};
</script>
<style lang="scss">
@import "./styles.scss";
</style>
