export default {
	name: "height-listing",
	data() {
		return {
			elementHeight:400,
			skipBottomHeight:20,
		};
	},
	methods: {
		calculateHeight() {
			this.$nextTick(() => {
				const elementHeightFixed = this.$refs.btt_height;
			  if (elementHeightFixed) {
				const rect = elementHeightFixed.$el ? elementHeightFixed.$el.getBoundingClientRect() : elementHeightFixed.getBoundingClientRect()
				const windowHeight = window.innerHeight
		
				// this.elementHeight = `${windowHeight - (rect.top + 60)}px`
				this.elementHeight = `${windowHeight - (rect.top + this.skipBottomHeight)}px`
				if(elementHeightFixed.$el){
				  elementHeightFixed.$el.style.height = this.elementHeight
				  elementHeightFixed.$el.style.overflowY = 'auto'
				//   elementHeightFixed.$el.style.overflowX = 'hidden'
				}else{
				  elementHeightFixed.style.height = this.elementHeight
				  elementHeightFixed.style.overflowY = 'auto'
				//   elementHeightFixed.style.overflowX = 'hidden'
				}
			  }
			})
		  }
	},
	beforeDestroy() {
		const _this = this;
		window.removeEventListener("resize", _this.calculateHeight)
	},
	beforeMount() {
		// this.init_linked();
	},
	mounted() {
		const _this = this;
		_this.calculateHeight()
		window.addEventListener("resize", _this.calculateHeight)
	},
};
